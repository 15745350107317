import { BrowserRouter } from "react-router-dom";

import { AuthProvider } from "./context/AuthContext";
import { RouteList } from "./routes";

import "react-toastify/dist/ReactToastify.css";
import "assets/fonts/index.scss";
import "./App.scss";

import { ModalProvider } from "context/ModalContext";
import ToastProvider from "context/ToastContext";
import { WebsocketProvider } from "context/WebsocketContext";
import { AchievementModal } from "components/modal/achievement";
import { WebsocketNotificationProvider } from "context/WebsocketNotificationContext";
import { AppContainer } from "components/ui/app-container";
import TagManager from "react-gtm-module";
import { HelmetProvider } from "react-helmet-async";
import TourSteps from "components/tour";

const tagManagerArgs = {
  gtmId: "G-Q3S7R9H1P0",
};
TagManager.initialize(tagManagerArgs);

(window as any).dataLayer.push({
  event: "pageview",
});

const App = () => (
  <HelmetProvider>
    <BrowserRouter>
      <AuthProvider>
        <WebsocketNotificationProvider>
          <WebsocketProvider>
            <ModalProvider>
              <TourSteps>
                <AppContainer>
                  <RouteList />
                </AppContainer>
              </TourSteps>
              <ToastProvider />
              <AchievementModal />
            </ModalProvider>
          </WebsocketProvider>
        </WebsocketNotificationProvider>
      </AuthProvider>
    </BrowserRouter>
  </HelmetProvider>
);

export default App;
